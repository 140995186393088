import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import Image from 'next/image'
import Slop from '@/components/slop';
import Link from 'next/link';
import Footer from '@/components/footer';
import { useRouter } from 'next/router';
import  secureLocalStorage  from  "react-secure-storage";

export default function Header() {
    const [menuNavList, setMenuNavList] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuLang, setMenuLang] = useState(false);
    const [data, setData] = useState([]);
    const [langMenuData, setLangMenuData] = useState([]);
    const router = useRouter();

    const setLangInit = () => {
        const checkLang = secureLocalStorage.getItem("lang");
        if(checkLang == null || checkLang == '') {
            secureLocalStorage.setItem("lang", 'en');
        }
        // console.log(checkLang);
    }


    const handleOpen = () => {
        setMenuOpen(!menuOpen); // Toggle accordion
        setMenuLang(false);
        setMenuNavList(true);
    };
    const handleLangOpen = () => {
        setMenuLang(!menuLang); // Toggle accordion
        if(menuLang) {
            setMenuNavList(true);
        } else {
            setMenuNavList(false);
        }
    };


    
    const setLangFn = (e) => {
        // console.log(e);
        secureLocalStorage.setItem("lang", e);
        router.push('/');
        setTimeout(() => {
            router.reload();
        }, 500);
    }

    const langMenu = () => {
        (async() => {
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=*&locale=en`).then((res) => res.json());
                // console.log(data);
                setLangMenuData(data.data.langMenu);
            } catch(err) {
                console.log(err)
            }
        })();
    }

    const dataCommon = () => {
        (async() => {
            const lang = secureLocalStorage.getItem("lang");
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=*&locale=${lang}`).then((res) => res.json());
                // console.log(data);
                setData(data.data);
            } catch(err) {
                console.log(err)
            }
        })();
    }
    const noLoader = (e) => {
        // e.preventDefault();
        // console.log(router.pathname);
        secureLocalStorage.setItem("pathname", router.pathname);
    }

    useEffect(() => { 
        setLangInit();
        dataCommon();
        langMenu();
    }, []);
    return (
        <header className={styles.header}>
            <div className={styles.stickybar}>
                { (router.pathname == '/') ?
                    <div className={styles.langBtn} onClick={() => {handleOpen(), handleLangOpen()}}>
                        <i className={styles.lang}></i>
                    </div>
                    : 
                    <Link className={styles.logo} href={'/'} onClick={noLoader}><Image src="/logo.png" alt="Sopexa" width={165} height={62} /></Link> 
                }
                <div className={`${styles.navBtn} ${menuOpen ? styles.open : ''}`} onClick={handleOpen}><i></i><i></i><i></i></div>
                
            </div>
            <div className={`${styles.menu} ${menuOpen ? styles.show : styles.hideme}`}>
                <div className={styles.stickynav}>
                    <div className={styles.langBtn} onClick={handleLangOpen}>
                        {menuLang ? <i className={styles.link}></i> : <i className={styles.lang}></i>}
                    </div>
                </div>
                <figure className={styles.logoNav}>
                    <Image src="/logo.png" alt="Sopexa" width={266} height={100} />
                </figure>
                <Slop color={'bg'} />
                <div className={styles.menuCnt}>
                    {menuNavList && <nav>
                        {data?.menu?.map((link, index) => (
                            <Link key={index} onClick={handleOpen} href={`/${link.url}`}>{link.text}</Link>
                        ))}
                    </nav> }
                    {menuLang && <nav className={styles.langNav}>
                        {langMenuData && <nav>
                            {langMenuData?.map((l, index) => (
                                <div key={index} className={styles.link} data-lang={l.lang} onClick={(e) => setLangFn(e.target.getAttribute('data-lang'))}>{l.text}</div>
                            ))}
                        </nav> }
                    </nav> }
                    <Footer />
                </div>
            </div>
        </header>
    );
}

